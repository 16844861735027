@import "~@cwlib/react-lib/lib/styles/fonts";

.form-check-input {
    width: $size-112;
    height: $size-112;

    &:checked {
        background-color: var(--colour-active);
        border-color: var(--colour-active);
    }

    &:focus {
        border-color: var(--colour-active);
        box-shadow: 0 0 0 0.25rem rgba(var(--colour-active), 0.25);
    }
}
