.invalid-feedback {
    color: $colour-invalid;
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1.375rem;
    margin-top: 0.25rem;
}

input {
    &.form-control {
        height: 2.625rem;
        border: 0.0625rem solid #dddddd;
        border-radius: 0.25rem;
        padding: 0.625rem 1rem;

        &.is-invalid {
            background-image: none;
            border-color: $colour-invalid;
        }
    }
}
