@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/boostrap-config";

@import '../../../node_modules/@cwlib/react-lib/lib/styles/spacing.scss';

$text-regular: 1rem;
$line-height-large: 22px;

// font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: bold;

// font-sizes
$font-size-487: $size-487;
$font-size-400: $size-400;
$font-size-325: $size-325;
$font-size-300: $size-300;
$font-size-275: $size-275;
$font-size-250: $size-250;
$font-size-225: $size-225;
$font-size-200: $size-200;
$font-size-162: $size-162;
$font-size-137: $size-137;
$font-size-125: $size-125;
$font-size-112: $size-112;
$font-size-100: $size-100;
$font-size-87: $size-87;
$font-size-75: $size-75;
$font-size-62: $size-62;
$font-size-37: $size-37;

/**
*   Spacing
*/
// line-height: using standard sizing of 1.2 (defined in bootstrap)

// letter spacing
$font-letter-spacing-1060: 4.5px; //card labels
$font-letter-spacing-150: 0.5px; //h1
$font-letter-spacing-120: 0.4px; //h2
$font-letter-spacing-100: 0.33px; //h3
$font-letter-spacing-90: 0.3px; //h4, h5
$font-letter-spacing-75: 0.25px; //h6
$font-letter-spacing-11: 0.11px;

$line-height-87: 0.875rem;
$line-height-125: 1.25rem;
$line-height-237: 2.375rem;
$line-height-250: $font-size-250;

$box-shadow: 0 0 0.625rem 0.1875rem var(--colour-border);
:root {
    .cw-login-form {
        text-align: start;

        .cw-login-form-alert {
            text-align: start;
        }
        .cw-login-form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: $font-size-100;
            gap: $font-size-100;
            @include media-breakpoint-down(md) {
                gap: $font-size-62;
            }

            h1 {
                width: fit-content;
                font-weight: bold;
                line-height: 3.25rem;
                letter-spacing: $font-letter-spacing-90;
                font-size: $font-size-275;
                margin-bottom: 0;

                @include media-breakpoint-down(lg) {
                    line-height: 2.375rem;
                    font-size: $font-size-200;
                    letter-spacing: $font-letter-spacing-75;
                }
            }

            > .link-prompt > a.link-info {
                margin: auto 0;
            }

            &.cw-login-form-group-title {
                margin-bottom: $font-size-137;
                gap: 0.5rem;
            }

            &.signin-links {
                a {
                    text-align: start;
                    &:hover {
                        cursor: pointer;
                    }
                }

                .cw-login-form-btns-group {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    gap: $size-100;

                    > .btn-cw {
                        width: 100%;
                    }
                }
            }

            .btn-cw {
                height: $font-size-275;
            }

            .textinput-wrapper {
                width: 100%;

                input {
                    width: inherit;

                    &.input-error {
                        background-image: none;
                    }
                }
            }
        }

        .login-form-errorMessage {
            color: var(--colour-red);
            font-size: $font-size-100;
            margin-top: 0.5rem;
            text-align: start;
        }
    }

    .divider {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .divider-line {
            width: 100%;
            height: 1px;
            background: var(--colour-muted);
        }

        .divider-text {
            text-transform: uppercase;
            color: var(--colour-text);
        }
    }
}
