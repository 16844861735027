.btn {
    min-height: 2.75rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.375rem;
    text-align: center;
    color: $colour-white;

    &:disabled {
        background-color: var(--colour-border);
        color: var(--colour-text);
        cursor: not-allowed;
    }

    &-active {
        background-color: var(--colour-active);

        &:hover {
            background-color: var(--colour-green);
            color: $colour-white;
        }
    }

    &-link {
        color: var(--colour-primary);
        text-decoration: none;

        &:disabled {
            background-color: $colour-white;
        }

        &:hover {
            color: var(--colour-red);
        }
    }

    &-secondary {
        border: none;
        background-color: var(--colour-border);
        color: var(--colour-text);

        &:hover {
            background-color: var(--colour-muted);
        }
    }
}
