@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/boostrap-config";

@import '../../../node_modules/@cwlib/react-lib/lib/styles/spacing.scss';

$text-regular: 1rem;
$line-height-large: 22px;

// font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: bold;

// font-sizes
$font-size-487: $size-487;
$font-size-400: $size-400;
$font-size-325: $size-325;
$font-size-300: $size-300;
$font-size-275: $size-275;
$font-size-250: $size-250;
$font-size-225: $size-225;
$font-size-200: $size-200;
$font-size-162: $size-162;
$font-size-137: $size-137;
$font-size-125: $size-125;
$font-size-112: $size-112;
$font-size-100: $size-100;
$font-size-87: $size-87;
$font-size-75: $size-75;
$font-size-62: $size-62;
$font-size-37: $size-37;

/**
*   Spacing
*/
// line-height: using standard sizing of 1.2 (defined in bootstrap)

// letter spacing
$font-letter-spacing-1060: 4.5px; //card labels
$font-letter-spacing-150: 0.5px; //h1
$font-letter-spacing-120: 0.4px; //h2
$font-letter-spacing-100: 0.33px; //h3
$font-letter-spacing-90: 0.3px; //h4, h5
$font-letter-spacing-75: 0.25px; //h6
$font-letter-spacing-11: 0.11px;

$line-height-87: 0.875rem;
$line-height-125: 1.25rem;
$line-height-237: 2.375rem;
$line-height-250: $font-size-250;

$box-shadow: 0 0 0.625rem 0.1875rem var(--colour-border);
.otp-input {
    margin: 1.25rem 0;
    display: flex;
    flex-direction: column;

    .inputs-wrapper {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .otp-field {
            flex: 0 1 auto;
            height: 3.625rem;
            overflow: hidden;
            border: 1px solid var(--colour-border);
            border-radius: 0.375rem;
            background-color: $colour-white;
            padding: 0;
            text-align: center;
            font-size: $font-size-137;
            width: 16.62%;
            + .otp-field {
                margin-left: 0.4375rem;
            }

            &:focus {
                border-color: var(--colour-active);
                outline: none;
            }

            &:invalid {
                border-color: $colour-invalid;
                outline: none;
            }
        }
    }

    &-complete .inputs-wrapper .otp-field {
        border-color: var(--colour-active);
        outline: none;
    }

    &-invalid .inputs-wrapper .otp-field {
        border-color: $colour-invalid;
        outline: none;

        &:focus {
            border-color: $colour-invalid;
            outline: none;
        }
    }

    .invalid-msg {
        color: $colour-invalid;
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.375rem;
        text-align: left;
    }
}
