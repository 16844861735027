a {
    color: inherit;
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.125rem;

    &.active {
        color: var(--colour-active);

        &:hover {
            color: var(--colour-green);
        }
    }

    &:hover {
        cursor: pointer;
        color: inherit;
    }
}
