.icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &-rounded {
        border-radius: 50%;
    }

    &-success {
        color: var(--colour-active);
        background-color: $honeydew;
    }

    &-error {
        color: $colour-invalid;
        background-color: $linen;
    }
}
