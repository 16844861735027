@import "node_modules/react-toastify/scss/main";

.Toastify__toast-container {
    &--top-center {
        top: 0;
        width: 100%;
    }
}

.Toastify__toast {
    padding: 0;
    border-radius: 0;
    margin-bottom: 0.5rem;
    min-height: auto;

    &-body {
        padding: 0;
    }
}
