.page {
    &-heading {
        text-align: center;
        color: var(--colour-muted);
        padding-bottom: 0.375rem;
        font-size: 0.875rem;
        font-weight: bold;
        letter-spacing: 0.25rem;
        line-height: 1.125rem;
        margin-bottom: 0;
    }

    &-title {
        color: var(--colour-hero);
        font-size: 1.625rem;
        font-weight: bold;
        letter-spacing: -0.16px;
        line-height: 2rem;
        text-align: center;
        padding-bottom: 0.625rem;
        margin-bottom: 0;
    }

    &-subtitle {
        color: var(--colour-text);
        font-size: 1rem;
        letter-spacing: -0.1px;
        line-height: 1.25rem;
        text-align: center;
        padding-bottom: 2rem;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
        &-heading {
            font-size: 1rem;
            letter-spacing: 0.25rem;
            line-height: 1.25rem;
        }

        &-title {
            font-size: 3rem;
            letter-spacing: -0.3px;
            line-height: 3.625rem;
        }

        &-subtitle {
            font-size: 1.25rem;
            letter-spacing: -0.13px;
            line-height: 1.5rem;
        }
    }
}
