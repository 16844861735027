$colour-invalid: #ea4b41;
$colour-white: #ffffff;
$colour-grey: #d0d0d0;
$colour-pink: #f8e8e9;
$colour-orange: #fa8c28;
$vermilion: #d74119;
$raisin-black: #212121;
$honeydew: #eaf9f0;
$linen: #fdedec;

// Password strength meter
$weak-password-colour: $colour-invalid;
$medium-password-colour: #f1c30f;
$strong-password-colour: #2ecc71;
